
body {
    font-size: 16px;
}

ul {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.landing-page-img {
    max-width: 662px;
    max-height: 626px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.leaflet-container {
    font: inherit
}

.gradient {
	background-image: linear-gradient(120deg, #378adc 50%, #fff 100%);
	}

.page-header {
	margin-top:  3.5rem ;
	font-size:  1.25rem;
	color:  #fff;
	font:  Roboto;
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.navbar {
	font-size:  1.1rem;
	font-weight:  bold;
	color:  #fff;
}

.cont {
  position: relative;
  width: 50%;
}

.img-responsive {
  opacity: 1;
  transition: .2s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cont:hover .img-responsive {
  opacity: 0.3;
}

.cont:hover .middle {
  opacity: 1;
}

.text {
  background-color: #378adc;
  color: white;
  font-weight:  bold;
  font-size: 22px;
  padding: 16px 32px;
}


 /* neu */
.contact-form{
    margin-bottom: 5%;
    width: 70%;
}
.contact-form .form-control{
    border-radius:1rem;
    margin-top: 2%;
}

.contact-form form{
    padding: 14%;
}

.contact-form form .row{
    margin-bottom: -7%;
}

.contact-form h2{
		font-weight:  bold;
    margin-bottom: 8%;
    text-align: center;
    color: #fff;
}
.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    margin-top:  5%;
    background: #dc3545;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
.btnContactSubmit
{
    width: 50%;
    border-radius: 1.5rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}

.info-box-heading {
    font: inherit;
    margin: 0 0 5px;
    color: #777;
    font-weight: bold;
}
